import { NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { httpInterceptorProviders } from './helpers/http-request.interceptor';
import { QuillConfigModule } from 'ngx-quill';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    QuillConfigModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'], // Estilos de texto
          [{ header: [1, 2, 3, 4, false] }], // Títulos
          [{ color: [] }, { background: [] }], // Cores
          [{ list: 'ordered' }, { list: 'bullet' }], // Listas
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' },
          ],
          ['clean'], // Limpar formatação
          ['link', 'image', 'video'], // Links, Imagens e Vídeos
        ],
        clipboard: {
          matchVisual: true,
        },
      },
    }),
  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
